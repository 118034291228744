import React, { useState, useEffect} from "react";
import axios from "axios";
import Posts from "../../data/Posts.js";
import _ from "lodash";
import Button from 'react-bootstrap/Button'



const TeamExecutiveBoard = () => {


    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [cargos, setCargos] = useState([]);
    const [allPosts, setAllPosts] = useState([]);
    const [isDirection, setDirection] = useState(false);
    const [ui, setUi] = useState([]);
    const [selectedCargo, setSelectedCargo] = useState('');
    const [counts, setCounts] = useState([])
    const [integrateds, setIntegrateds] = useState([]);
    let savedState = {}

    useEffect(() => {
        savedState = localStorage.getItem("teamExecutiveBoardState");
        if (savedState) {
            const { posts: savedPosts, selectedCargo: savedSelectedCargo } = JSON.parse(savedState);
            setPosts(savedPosts);
            setSelectedCargo(savedSelectedCargo);
        }
    }, []); // Empty dependency array ensures this effect runs only once when component mounts

    // Save state to localStorage whenever it changes
    useEffect(() => {
        localStorage.setItem("teamExecutiveBoardState", JSON.stringify({ posts, selectedCargo }));
    }, [posts, selectedCargo]);

    useEffect(() => {
        const fetchPosts = async () => {
            setLoading(true);
            const res = await axios.get(window.config.DEV_LINK + "/investigador/getByUi/" + window.config.id);
            setAllPosts(res.data.payload);
            const integrados = _.filter(res.data.payload, item => item.TIPO_MEMBRO_ID === '3' || item.TIPO_MEMBRO_ID === '1');
            const colaboradores = _.filter(res.data.payload, item => item.TIPO_MEMBRO_ID === '2' || item.TIPO_MEMBRO_ID === '17');
            const bolseiros = _.filter(res.data.payload, item => item.TIPO_MEMBRO_ID === '6');
            const phd = _.filter(res.data.payload, item => item.TIPO_MEMBRO_ID === '16' || item.TIPO_MEMBRO_ID === '17');
            setIntegrateds(integrados)
            if(!savedState){
                setPosts(integrados);
            }
    
            const updatedCounts = {
                1: integrados.length,
                2: colaboradores.length,
                6: bolseiros.length,    
                16: phd.length
            };
            setCounts(updatedCounts);
            setLoading(false);
        };
    
        fetchPosts();
    }, [cargos]);

    useEffect(() => {

        const fetchUi = async () => {
            setLoading(true);
            const res = await axios.get(window.config.DEV_LINK + "/uis/byId?idUi=" + window.config.id);
            setUi(res.data.payload)
        };

        fetchUi();
        setLoading(false);
    }, []);


    useEffect(() => {

        getCargos()
    }, []);


    const getCargos = async () => {
        setLoading(true);
        try {
            await axios.get(window.config.DEV_LINK + '/investigador/getTipo').then((response) => {
                setCargos(_.filter(response.data.payload, cargo => !_.includes(["3", "4", "5"], cargo.ID)))
                if(!savedState){
                setSelectedCargo(response.data.payload[0].descr_en)
                }
            });

        } catch (error) {
            console.error(error);
        }
        setLoading(false);
    }



    function handlePosts(e) {
        let typeCargo = e.target.value;
        setSelectedCargo(e.target.value)
        //para os botoes nao normais 

        if (typeCargo === "Integrated") {
            let filtPosts = allPosts.filter((fPosts) => {
                return fPosts.descr_en === typeCargo || fPosts.descr_en === "Direction";
            });
            setPosts(filtPosts);
        }
        if (typeCargo === "Colaborators") {
            let filtPosts = allPosts.filter((fPosts) => {
                return fPosts.descr_en === typeCargo || fPosts.descr_en === "PhD Student / Colaborator";
            });
            setPosts(filtPosts);
        }
        if (typeCargo === "PhD student") {
            let filtPosts = allPosts.filter((fPosts) => {
                return fPosts.descr_en === typeCargo || fPosts.descr_en === "PhD Student / Colaborator";
            });
            setPosts(filtPosts);
        }
        if (typeCargo === "Scholarship Holders") {
            let filtPosts = allPosts.filter((fPosts) => {
                return fPosts.descr_en === typeCargo;
            });
            setPosts(filtPosts);
        }
    }
    

    return (
        <div>
            {/* <!-- start team area --> */}
            <section className={`team p-120 ${window.location.pathname === "/team-executive-board" ? "team-page" : 3}`}>
                {loading &&
                    <div className="preloader">
                        <div className="main-circle">
                            <div className="green-circle">
                                <div className="brown-circle">

                                </div>
                            </div>
                        </div>
                    </div>

                }
                <div>
                    <div className="row">
                        <div className="col col-lg-12 col-sm-12" style={{ textAlign: 'center' }}>
                            {cargos.map((cargo, index) => {
                                const isSelected = cargo.descr_en === selectedCargo;
                                const numberOfInv = counts[cargo.ID] || 0; 
                                return (
                                    <Button
                                        className={`mybutton ${isSelected ? 'selected' : ''}`}
                                        size="lg"
                                        style={{ margin: '20px' }}
                                        key={index}
                                        value={cargo.descr_en}
                                        onClick={handlePosts}
                                    >
                                        {cargo.descr_en} ({numberOfInv})
                                    </Button>
                                );
                            })}
                        </div>
                    </div>
                    <div className="container" style={{ minHeight:'312px',padding: '10px' }}>
                        <Posts posts={posts} loading={loading} ui={ui} direction={isDirection} />
                    </div>
                </div>

            </section>
            {/* <!-- end team area --> */}
        </div>
    );
}

export default TeamExecutiveBoard;
