import React, { memo } from "react";
import { Link } from "react-router-dom";
let sauce;

export const Posts = ({ posts, loading, ui, direction }) => {;
  if (loading) {
    return (
      <h3 style={{ marginTop: "300px", height: "100%", textAlign: "center" }}>
        Loading...
      </h3>
    );
  }
  return (
    <div className="col-lg-12">
      <div className="row justify-content-center">
        {posts.map((post) => {

          if (post.foto_path === null || post.foto_path === "") {
            sauce = "https://adit.ipvc.pt/backend/backend/fotos/adit/test.svg";
          } else {
            sauce =
              "https://adit.ipvc.pt/modulosipvc_producao/frontend/images/investigador/" +
              post.foto_path;
          }

          return (
            <div
              className="col-sm-6 col-xl-3 col-lg-4"
              key={post.INVESTIGADOR_ID}
            >
              <div className="item" key={post.INVESTIGADOR_ID}>
                <Link
                  to={{
                    pathname:
                      "/team-details/" +
                      (post.USERNAME ? post.USERNAME : post.INVESTIGADOR_ID),
                    state: {
                      userID: post.ID,
                      page_link: post.USERNAME,
                      posts: posts,
                    },
                  }}
                >
                  <div
                    className="image"
                    style={{ display: "grid", justifyContent: "center" }}
                  >
                    <img src={sauce} className="img-fluid" alt="Team Member" />
                  </div>
                </Link>
                <div className="eachTeam" style={{ textAlign: "center" }}>
                  <span style={{ fontSize: "24px" }}>
                    <b>{post.nome_website} </b>
                  </span>

                  <p style={{ fontSize: "18px", color: "Black" }}>
                    {post.EMAIL_EXTERNO}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Posts;
